import { forwardRef } from 'react';

import { mapClassNamesToSlots, TVComponentProps } from '../../../../style-system';
import { useFieldContext } from '../context';

import { labelStyles } from './label.styles';

type LabelProps = TVComponentProps<'label', typeof labelStyles> & {
    tooltip?: React.ReactNode;
};

const Label = forwardRef<HTMLLabelElement, LabelProps>(function Label(
    { tooltip, classNames, onMouseDown, size: sizeProp, ...rest },
    ref,
) {
    const handleOnMouseDown: React.MouseEventHandler<HTMLLabelElement> = event => {
        // only prevent text selection if clicking inside the label itself
        const target = event.target as HTMLElement;
        if (target.closest('button, input, select, textarea')) return;

        onMouseDown?.(event);
        // prevent text selection when double clicking label
        if (!event.defaultPrevented && event.detail > 1) event.preventDefault();
    };

    const field = useFieldContext();
    const { label, wrapper } = mapClassNamesToSlots(labelStyles, {
        size: sizeProp ?? field?.size,
        ...rest,
        classNames,
    });
    const labelProps = field?.getLabelProps(rest, ref);

    return (
        <div className={wrapper}>
            <label {...labelProps} className={label} ref={ref} onMouseDown={handleOnMouseDown} />
            {tooltip}
        </div>
    );
});

export { Label };
