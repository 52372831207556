import { TVComponentWithElementProps } from '../../../../style-system/models';

import { fieldOrientationStyles } from './field-orientation.styles';

type FieldOrientationProps = TVComponentWithElementProps<'span', typeof fieldOrientationStyles>;

const FieldOrientation = ({ children, direction = 'vertical', reverseFlow }: FieldOrientationProps) => {
    return <span className={fieldOrientationStyles({ direction, reverseFlow })}>{children}</span>;
};

type OrientationValues = Pick<FieldOrientationProps, 'direction' | 'reverseFlow'>;
type OrientationProps<EnableReverseFlow extends boolean = true> = {
    orientation?: EnableReverseFlow extends false
        ? OrientationValues['direction']
        : {
              direction?: OrientationValues['direction'];
              reverseFlow?: OrientationValues['reverseFlow'];
          };
};

export type { FieldOrientationProps, OrientationProps };
export { FieldOrientation };
