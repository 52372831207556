import { tVariants } from '../../../../style-system';

const fieldOrientationStyles = tVariants({
    base: 'flex flex-col gap-3',
    defaultVariants: {
        direction: 'horizontal',
    },
    variants: {
        direction: {
            horizontal: 'flex-row',
            vertical: 'flex-col',
        },
        reverseFlow: {
            true: 'justify-end',
        },
    },
    compoundVariants: [
        {
            direction: 'horizontal',
            reverseFlow: true,
            className: 'flex-row-reverse justify-end',
        },
        {
            direction: 'vertical',
            reverseFlow: true,
            className: 'flex-col-reverse items-end',
        },
        {
            direction: 'vertical',
            className: 'gap-0.5',
        },
    ],
});

export { fieldOrientationStyles };
